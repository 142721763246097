import { Body, H1, H4 } from "@casavo/habitat"
import Link from "next/link"
import useTranslation from "next-translate/useTranslation"

import { CategoryMenu } from "@app/components/blog/CategoryMenu"
import { PageSection } from "@app/shared/components/layout/PageSection"
import { MyImage } from "@app/shared/components/MyImage"
import theme from "@app/shared/design-lib/style-theme/theme.css"
import { imageFitContain } from "@app/shared/design-lib/styles.css"
import { PageKind } from "@app/shared/types/pages"
import { postToPreview } from "@app/shared/utils/blog/utils"
import { WordPressPost } from "@app/shared/utils/blog/wordpress-blog"
import { BlogCategoryType } from "@shared/types/website-pages"
import * as casavoPages from "@shared/utils/pages"

import { ArticleCard, ArticleDimension } from "../../shared/components/ArticleCard"
import { BlogPageNavigation } from "../blog/BlogPageNavigation"
import { RecommendedArticles } from "../blog-post/RecommendedArticles"
import { container } from "../contact-us/styles.css"

import {
  articlesWrapper,
  flex,
  imageWrapper,
  link,
  marginLeft,
  marginTop,
  noResults,
  paddingRight,
  space,
  greyText,
} from "./style.css"

interface Props {
  mostReadPosts?: Array<any>
  posts: WordPressPost[]
  tag: keyof BlogCategoryType | string
  totalPages?: number
}

export const BlogCategoryContent = ({ mostReadPosts = [], posts, tag, totalPages = 1 }: Props) => {
  const { lang, t } = useTranslation()
  const articles = posts

  const categoryTitle: string = t(`blog:categories.${tag}`)
  const blogUrl = `/${lang}/${casavoPages.create(lang as Locale)(PageKind.blog)}`
  const categoriesImg = `/images/blog/illustrations/categories/${tag}.svg`

  return (
    <>
      {tag === "customer-stories" ? null : <CategoryMenu selected={tag} showAllArticles={true} />}
      <PageSection hasPadding={true} marginTop="xl" size="large">
        <div className={flex({ hasMargin: tag === "customer-stories" })}>
          <div className={imageWrapper}>
            <MyImage alt={tag} className={imageFitContain} height={200} src={categoriesImg} width={200} />
          </div>
          <div className={marginLeft}>
            <div className={greyText}>
              <H4 noMargin>
                {tag === "all-articles" ? t("blog:headline.all-articles") : t("blog:headline.category")}
              </H4>
            </div>
            <H1>{tag === "all-articles" ? t("blog:categoryMenu.allArticles") : categoryTitle}</H1>
          </div>
        </div>
        <div className={marginTop}>
          <Body noMargin size="l">
            {t(`blog:description.${tag}`)}
          </Body>
        </div>
      </PageSection>

      <BlogPageNavigation tag={tag} totalPages={totalPages} />

      {articles.length < 1 ? (
        <div className={container}>
          <H1>{t(`blog:no-results`)}</H1>
          <div className={noResults}>
            <Link aria-label={t(`blog:no-results`)} className={link} href={blogUrl}>
              <span className={paddingRight}>
                <svg
                  fill="none"
                  height="16"
                  viewBox="0 0 10 16"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    d="M8.58579 0.292847L0.878679 7.99995L8.58579 15.7071L10 14.2928L3.70789 7.99995L10 1.70706L8.58579 0.292847Z"
                    fill={theme.colors.greyscale600}
                    fillRule="evenodd"
                  />
                </svg>
              </span>
              <Body noMargin strong>
                {t("blog:categoryMenu.linkHome")}
              </Body>
            </Link>
          </div>
        </div>
      ) : (
        <PageSection hasPadding={true} size="large">
          <div className={articlesWrapper}>
            {articles.map((article, index) => {
              return (
                <ArticleCard
                  key={`article-${index}`}
                  dimension={ArticleDimension.Small}
                  props={postToPreview(article, lang)}
                />
              )
            })}
          </div>
        </PageSection>
      )}

      <BlogPageNavigation tag={tag} totalPages={totalPages} />

      {lang === "it" || lang === "en" ? (
        <RecommendedArticles greyCard={true} lightBlue={true} recentArticles={mostReadPosts.slice(0, 3)} />
      ) : (
        <div className={space} />
      )}
    </>
  )
}
