import { useRouter } from "next/router"
import useTranslation from "next-translate/useTranslation"
import React from "react"

import { ArrowLeft } from "@app/shared/components/icons/ArrowLeft"
import { ArrowRight } from "@app/shared/components/icons/ArrowRight"
import { PageSection } from "@app/shared/components/layout/PageSection"
import { PageKind } from "@app/shared/types/pages"
import * as casavoPages from "@shared/utils/pages"

import { PageButton } from "./PageButton"
import { flexEnd, spacing } from "./style.css"

interface Props {
  tag: string
  totalPages: number
}

export const BlogPageNavigation: React.FC<Props> = ({ tag, totalPages }) => {
  const { lang } = useTranslation()
  const router = useRouter()
  const currentPage = parseInt((router.query?.number as string) || "1")
  const link =
    tag === "all-articles"
      ? `/${casavoPages.create(lang as Locale)(PageKind.blog)}/${tag}`
      : `/${casavoPages.create(lang as Locale)(PageKind.blog)}/category/${tag}`

  return (
    <>
      {totalPages > 1 && (
        <PageSection size="large">
          <div className={flexEnd}>
            <div className={spacing}>
              {/* Previous */}
              <PageButton
                disabled={currentPage <= 1}
                href={`${link}/${currentPage - 1 !== 1 ? `page/${currentPage - 1}` : ""}`}
              >
                <ArrowLeft />
              </PageButton>

              {/* Next */}
              {!(currentPage >= totalPages) && (
                <PageButton disabled={currentPage >= totalPages} href={`${link}/page/${currentPage + 1}`}>
                  <ArrowRight />
                </PageButton>
              )}
            </div>
          </div>
        </PageSection>
      )}
    </>
  )
}
